<template>
  <card-layout>
    <div class="row">
      <div class="col-md-3">
        <label>Staff First Name</label>
        <search-input
          placeholder="First Name"
          class-name="input-group mb-3"
          v-model="searchParam"
          @change-input="changeSearchVal"
        ></search-input>
      </div>
      <div class="col-md-3">
        <label>Select building</label>
        <multi-select
          placeholder="Select Building"
          :options="allBuildings"
          mode="tags"
          :searchable="true"
          v-model="buildingsSelected"
        ></multi-select>
      </div>
      <div class="col-md-3" style="z-index: 100">
        <label>Select Month</label>
        <month-picker-input
          :default-year="defaultYear"
          :default-month="defaultMonth"
          @change="showDate"
        ></month-picker-input>
      </div>
    </div>
    <hr />
  </card-layout>
  <div class="row overflow-visible">
    <table-view :isLoading="isLoading">
      <template #card-header>
        <div class="col-2 ml-auto">
          <download-excel
            :data="cleanedDetails"
            :fields="json_fields"
            class="btn btn-success"
            type="xlsx"
            :name="excelFileName"
          >
            Download Excel
            <i class="fa fa-download"></i>
          </download-excel>
        </div>
      </template>
      <template #table-header>
        <th>Sr No.</th>
        <th>Staff Name</th>
        <th>Cleaned</th>
        <th>Receivable Amount</th>
      </template>
      <template #default>
        <tr v-for="(clean, index) in cleanedDetails" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            {{
              clean.staff_firstname +
              " " +
              clean.staff_middlename +
              " " +
              clean.staff_lastname
            }}
          </td>
          <td>{{ clean.cleaned_times }} times</td>
          <td>₹ {{ parseFloat(clean.payment_per_cleaned_time) }}</td>
        </tr>
      </template>
    </table-view>
  </div>
</template>
<script>
import TableView from "../UI/TableView.vue";
import MonthPickerInput from "vue-month-picker/src/MonthPickerInput.vue";
import SearchInput from "../UI/SearchInput.vue";
import debounce from "lodash.debounce";
export default {
  components: {
    TableView,
    MonthPickerInput,
    SearchInput,
  },
  data() {
    return {
      json_fields: {
        "First Name": "staff_firstname",
        "Middle Name": "staff_middlename",
        "Last Name": "staff_lastname",
        "Cleaned Times": "cleaned_times",
        "Receivable Amount": "payment_per_cleaned_time",
      },
      isLoading: false,
      searchParam: null,
      buildingsSelected: [],
      cleanedDetails: [],
      reportDate: null,
      defaultYear: new Date().getFullYear(),
      defaultMonth: new Date().getMonth() + 1,
      allBuildings: [],
      excelFileName: "Cleaned Times Report ",
    };
  },
  watch: {
    buildingsSelected() {
      this.fetchReportData(this.reportDate);
    },
  },
  methods: {
    fetchBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS
        )
        .then((res) => {
          if (res.status === 200) {
            this.allBuildings = res.data.data;
            this.convertKeys();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Methods.toastError(err?.response?.data?.message);
        });
    },
    convertKeys() {
      this.allBuildings.forEach((data) => {
        this.$Methods.renameKey(data, "alias", "label", "building_id", "value");
      });
    },
    changeSearchVal(input) {
      this.searchParam = input;
      this.fetchReportData(this.reportDate);
    },
    showDate(date) {
      this.reportDate = date;
      this.fetchReportData(date);
    },
    fetchReportData: debounce(function (date) {
      this.excelFileName =
        "Cleaned Times Report " +
        this.reportDate.month +
        " " +
        this.reportDate.year;
      this.isLoading = true;
      const body = {
        month: date?.monthIndex,
        year: date?.year,
        name: this.searchParam,
        building_ids: this.buildingsSelected.join(),
      };
      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_CLEANED_TIMES_REPORT,
          body
        )
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.cleanedDetails = res.data.data;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$Methods.toastError(err.response.data.message);
        });
    }, 500),
  },
  mounted() {
    this.fetchBuildings();
  },
};
</script>
