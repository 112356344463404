export default {
  BaseUrl: "https://server.thecarebuzz.com/api",
  // BaseUrl: "http://127.0.0.1:8000/api",
  ApiEndpoints: {
    TEST: "/test",
    LOGIN: "/login",
    GET_TOKEN_STATUS: "/user",
    LOGOUT: "/logout",
    CHECK_PASSWORD: "/checkPassword",
    CHANGE_PASSWORD: "/changePassword",

    GET_ALL_COMPANY_NAME: "/acCompanies",

    AC_COMPANY: "/acCompany",
    RESOURCE_CATEGORY: "/category",

    DEPARTMENT_API: "/department",
    GET_DEPT_BUILDING_WISE: "/getDeptBuildingWise",

    GET_ALL_BUILDINGS: "/getAllBuildings",
    ADD_NEW_BUILDING: "/addNewBuilding",
    DELETE_BUILDING: "/deleteBuilding",
    GET_BUILDING: "/getBuildingData",
    EDIT_BUILDING: "/updateBuilding",
    GET_UNASSIGNED_BUILDINGS: "/getUnassignedBuilding",
    GET_ASSIGNED_BUILDINGS: "/getAssignedBuilding",
    ASSIGN_BUILDING_TO_STAFF: "/assignBuilding",
    UNASSIGN_BUILDING_FROM_STAFF: "/unAssignBuilding",

    RESOURCE_API: "/resource",
    SEARCH_RESOURCE: "/searchResource",
    GET_DISTRIBUTED_RESOURCE: "/getDistributedResource",
    GET_RESOURCE_BUILDING_WISE: "/getResourcesBuildingWise",

    SUB_RESOURCE_API: "/sub_resource",
    SEARCH_SUB_RESOURCE: "/searchSubResource",
    GET_SUB_RESOURCE_OF_RESOURCE: "/getSubResourceOfResource",

    AIR_CONDITIONER_API: "/air_conditioner",
    SEARCH_AC: "/searchAC",

    STAFF_API: "/staff",
    CHANGE_STAFF_STATUS: "/changeStaffStatus",
    GET_STAFF_DEPT_WISE: "/getCaretakerOrStaffDeptWise",
    SEARCH_STAFF: "/searchStaff",
    GET_ASSIGNED_AC_COUNT_STAFF: "/fetchStaffAssignedACCount",
    GET_ASSIGNED_AC_DATA_LIST: "/getStaffAssignedACDetails",

    GET_UNASSIGNED_AC: "/getUnAssignedAc",
    ASSIGN_AC: "/assginACToStaff",
    FETCH_ASSIGNED_AC: "/fetchAssignedAC",
    UNASSIGN_AC: "/unAssginACToStaff",

    FETCH_CLEANING_DATA: "/fetchCleaningData",
    FETCH_NOT_CLEANED_DATA: "/fetchNotCompletedWork",
    FETCH_STAFF_CLEAN_HISTORY: "/fetchStaffCleanHistroy",
    FETCH_CLEANING_IMAGE: "/fetchCleanImage",
    FETCH_SINGLE_CLEANING_DATA: "/fetchSingleCleanDetail",
    UPDATE_CLEANING_STATUS: "/updateCleaningsStatus",

    FETCH_DASHBOARD_DATA: "/fetchDashboardDetails",

    ADD_NEW_ADMIN: "/addNewAdmin",
    ACTIVATE_ACCOUNT: "/activateAccount",
    FORGOT_PASSWORD: "/forgotPassword",
    EDIT_FORGOT_PASSWORD: "/editForgotPassword",

    FETCH_DELETED_STAFF: "/getDeletedStaff",
    RESTORE_STAFF: "/restoreStaff",

    FETCH_DELETED_AC: "/getDeletedAC",
    RESTORE_AC: "/restoreAC",

    FETCH_DELETED_RESOURCE: "/getDeletedResource",
    RESTORE_RESOURCE:"/restoreResource",

    FETCH_DELETED_SUBRESOURCE: "/getAllDeletedSubResources",
    RESTORE_SUBRESOURCE:"/restoreSubResource",

    FETCH_DELETED_BUILDING: "/getAllDeletedBuilding",
    RESTORE_BUILDING:"/restoreBuilding",

    FETCH_DELETED_DEPARTMENT: "/getAllDeletedDepartment",
    RESTORE_DEPARTMENT:"/restoreDepartment",

    FETCH_DELETED_COMPANY: "/getAllDeletedCompany",
    RESTORE_COMPANY:"/restoreCompany",

    FETCH_CLEANED_TIMES_REPORT: "/cleanedTimesReport"
  },
};
